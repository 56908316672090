/**
 * Hack because localstorage and session don't work on iOS
 */

const storage = {}

export const localStore = {
    getItem: (key) => {
        try {
            if (typeof window === "undefined") return undefined
            return localStorage.getItem(key)
        } catch (e) {
            console.warn('LocalStorage not supported.');
            return storage[key]
        }
    },
    setItem: (key, value) => {
        try {
            if (typeof window === "undefined") return
            localStorage.setItem(key, value)
        } catch (e) {
            console.warn('LocalStorage not supported.');
            storage[key] = value
        }
    }
}

export const sessionStore = {
    getItem: (key) => {
        try {
            if (typeof window === "undefined") return undefined
            return sessionStorage.getItem(key)
        } catch (e) {
            console.warn('LocalStorage not supported.');
            return storage[key]
        }
    },
    setItem: (key, value) => {
        try {
            if (typeof window === "undefined") return
            sessionStorage.setItem(key, value)
        } catch (e) {
            console.warn('LocalStorage not supported.');
            storage[key] = value
        }
    }
}