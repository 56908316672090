/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const LogRocket = require("logrocket")

const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "production"

exports.onClientEntry = () => {
  if (activeEnv !== "development") LogRocket.init("czc1pn/refer-o-scope-sqs")
}
