// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---plugins-gatsby-grapesjs-page-builder-src-pages-admin-jsx": () => import("./../plugins/gatsby-grapesjs-page-builder/src/pages/admin.jsx" /* webpackChunkName: "component---plugins-gatsby-grapesjs-page-builder-src-pages-admin-jsx" */),
  "component---plugins-gatsby-grapesjs-page-builder-src-pages-callback-jsx": () => import("./../plugins/gatsby-grapesjs-page-builder/src/pages/callback.jsx" /* webpackChunkName: "component---plugins-gatsby-grapesjs-page-builder-src-pages-callback-jsx" */),
  "component---plugins-gatsby-grapesjs-page-builder-src-templates-page-template-jsx": () => import("./../plugins/gatsby-grapesjs-page-builder/src/templates/PageTemplate.jsx" /* webpackChunkName: "component---plugins-gatsby-grapesjs-page-builder-src-templates-page-template-jsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pb-containers-questionnaire-js": () => import("./../src/pb-containers/Questionnaire.js" /* webpackChunkName: "component---src-pb-containers-questionnaire-js" */),
  "component---src-pb-containers-recommandations-js": () => import("./../src/pb-containers/Recommandations.js" /* webpackChunkName: "component---src-pb-containers-recommandations-js" */)
}

