import auth0 from "auth0-js"
import {
  navigate
} from "gatsby"
import {
  localStore
} from "./customStorage"

const isBrowser = typeof window !== "undefined"
console.log("IS BROWSER?", isBrowser)

const auth = isBrowser ?
  new auth0.WebAuth({
    domain: process.env.GATSBY_AUTH0_DOMAIN,
    clientID: process.env.GATSBY_AUTH0_CLIENTID,
    redirectUri: process.env.GATSBY_AUTH0_CALLBACK,
    responseType: "token id_token",
    scope: "openid profile email",
  }) :
  {}

const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: false,
}

let user = {}

export const isAuthenticated = () => {
  if (!isBrowser) {
    return
  }

  return localStore.getItem("isLoggedIn") === "true"
}

export const login = () => {
  if (!isBrowser) {
    return
  }

  console.log("LOGIN TO AUTH0", auth.authorize)
  auth.authorize()
}

const setSession = (cb = () => {}) => (err, authResult) => {
  if (err) {
    navigate("/")
    cb()
    return
  }


  if (authResult && authResult.accessToken && authResult.idToken) {
    console.log("SETTING NEW SESSION")
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
    tokens.accessToken = authResult.accessToken
    tokens.idToken = authResult.idToken
    tokens.expiresAt = expiresAt
    user = authResult.idTokenPayload
    localStore.setItem("isLoggedIn", true)
    if (typeof window !== "undefined" && window.location.pathname === "/callback") navigate("/admin/editor?page=b67aad8d-dbb6-457c-8786-85357b83472a")
    cb()
  }
}

export const silentAuth = callback => {
  if (!isAuthenticated()) return callback()
  auth.checkSession({}, setSession(callback))
}

export const handleAuthentication = () => {
  if (!isBrowser) {
    return
  }

  auth.parseHash(setSession())
}

export const getProfile = () => {
  return user
}

export const logout = () => {
  localStore.setItem("isLoggedIn", false)
  auth.logout()
}